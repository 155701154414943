import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import config from '../coreFIles/config';
import Header from '../directives/header';
import Leftnav from '../directives/leftSidebar';
import Rightnav from '../directives/rightSidebar';
import Footer from '../directives/footer';
import Cookies from 'js-cookie';
import { getFavouriteAdsAction,SaveFavouriteAdsAction } from '../Action/action';

const loginData = (!Cookies.get('loginSuccessMember')) ? [] : JSON.parse(Cookies.get('loginSuccessMember'));

const FavoritesAds = () => {
  const [favAds, setFavAds] = useState([]); 
  const [spinLoader, setSpinLoader] = useState(true);
  

  useEffect(() => {
  
      if (loginData) {

        getFavoriteAds()

      } else {
        toast.error('Please login first.');
      }
 
  
  }, []);

  

  const getFavoriteAds = async () => {
    try {
      if (!Cookies.get('loginSuccessMember')) {
        window.location.href = `${config.baseUrl}`;
      }else{
      const res = await getFavouriteAdsAction({ memberid: loginData.id });
    
      if (res.success) {
        setFavAds(res.data); 
        console.log(res.data)
      } else {
        toast.error(`Failed to fetch favorite ads: ${res.error}`);
      }
      console.log(res)
    } }catch (error) {
      toast.error(`An error occurred while fetching favorite ads: ${error.message}`);
    } finally {
      setSpinLoader(false);
    }
  };

  // handle fav
  const handleFavorite = async (adId) => {
		
		try {
      if (!Cookies.get('loginSuccessMember')) {
        toast.error('Please login first.');
        return null;
      }else{
			let res = await SaveFavouriteAdsAction({
				memberid: loginData.id,
				adid: adId
			});
			if (res.success) {
				toast.success(res.msg);
				getFavoriteAds()
			}
			else {
				toast.error(res.msg);
				getFavoriteAds()
			}


		}} catch (error) {
			console.error('Error saving favorite ad:', error);
			toast.error('Failed to save ad to favorites');
		}


	};
  return (
    <>
      <Header />
      <Toaster/>
     
      <div className="add-section mb-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Leftnav />
            </div>
            <div className="col-lg-6">
              {spinLoader ? (
                <div className="buy-end-sell">
                  <p>Loading your favorite ads...</p>
                
                </div>
              ) : favAds.length > 0 ? (
                <div className="buy-end-sell">
                  <h2 className="page-heading">Your Favorite Ads</h2>
                  {favAds.map((row,index) => (
                    <div key={index} className={row.index % 2 === 0 ? 'section-listing' : 'section-listing section-listing-new'}>
                      <div className="main-item">
                        <div className="fast-block">
                        {row.addImg ? (
																		<img src={config.apiUrl + row.addImg} alt={row.title} />
																	) : (
																		<img src={config.baseUrl + "img/noImage.png"} alt={row.title + row.id} />
																	)}
                        </div>
                        <div className="second-block">
                          <Link className="fordhead" to="#">
                            {row.title}
                          </Link>
                        
                            <p><span><i className="fa-solid fa-dollar-sign"></i></span> ${row.sell_price}</p>
                        
                          <p><span><i className="fa-solid fa-location-dot"></i></span> {row.suburb} ({row.area})</p>
                        </div>
                        	
																	<a
																		className="fevcls"
																		title=""
																		onClick={() => handleFavorite(row.id)}
																	>
																		<i className="fa fa-heart" aria-hidden="true"></i>
																	</a>
																	
                      </div>
                      
															
																	

                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <p><strong>No favorite ads found.</strong></p>
                </div>
              )}
            </div>
            <div className="col-lg-3">
              <Rightnav />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FavoritesAds;
