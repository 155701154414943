import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import config from "../coreFIles/config";
import Header from '../directives/header'
import Leftnav from '../directives/leftSidebar'
import Rightnav from '../directives/rightSidebar'
import Footer from '../directives/footer'
import Cookies from 'js-cookie'
import { LoginAction } from '../Action/action';

const Domainstyle = (!Cookies.get('domainstyle')) ? [] : JSON.parse(Cookies.get('domainstyle'));
const Login = () => {
	// style
	const timeColor = `#${Domainstyle[0]?.time_color}`;
	const headerButtonColor = `#${Domainstyle[0]?.header_buttoncolor}`;
	const headerMenuColor = `#${Domainstyle[0]?.header_menu}`;
	const headerMenuHoverColor = `#${Domainstyle[0]?.header_menu_hover}`;
	const header_search_text = `#${Domainstyle[0]?.header_search_text}`
	// style end

	const [form, setForm] = useState({ email: '', password: '' });
	const [validatioError, setvalidatioError] = useState({});
	
	if (Cookies.get('loginSuccessMember')) {
		window.location.href = `${config.baseUrl}`;
	}
	
	const inputHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}
	
	function validate() {
		let emailError = "";
		let passwordError = "";
	
		if (form.email === '') {
			emailError = "Email is required."
		}
		if (form.password === '') {
			passwordError = "Password is required."
		}
		
		if (emailError || passwordError) {
			setvalidatioError({
				emailError, passwordError
			})
			
			return false
		} else {
			return true
		}
	}
	
	const SubmitForm = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {
	
		}
		else {
			let res = await LoginAction(form);
			if (res.success) {
				toast.success(res.msg);
				Cookies.set('loginSuccessMember', JSON.stringify(res.data));
				setTimeout(() => {
					window.location.href = `${config.baseUrl}`;
				}, 1200);
			} else {
				toast.error(res.msg);
			}
		}
	}
	
	//~ function validateEmail(email) {
		//~ // Regular expression for email validation
		//~ const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
		//~ return regex.test(email);
	//~ }
	
	return(
		<>
			<Header />
			
			<div className="add-section mb-3">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Leftnav />
						</div>
						<div className="col-lg-6">
							<Toaster />
							
							<div className="classification-section">
								<h2 className="page-heading" style={{background:headerMenuColor}}>Member Login</h2>
								
								<form action='' method='post'>
									<div className="bondiclassified-fromsection">
										<div className="catagoryfrom">
										<div className="member-login">
											<div className="row">
												<div className="col-md-12 col-sm-12 user">
													<img src={config.baseUrl + "img/user.png"} alt='img' />
												</div>
												<div className="col-md-12 col-sm-12">
													<div className="form-group">
														{/* <label for="memberemail">Email</label> */}
														<input type="text" className="form-control" name="email" id="email" placeholder="Enter Your Email" onChange={inputHandler} />
														
														<span className="text-danger">{validatioError.emailError}</span>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-12 col-sm-12">
													<div className="form-group">
														{/* <label for="memberpassword">Password</label> */}
														<input type="password" className="form-control" name="password" id="password" placeholder="Enter Your Password" onChange={inputHandler} />
														
														<span className="text-danger">{validatioError.passwordError}</span>
													</div>
													<Link to={config.baseUrl + 'forgot-password'} className="forgot">Forgot Password?</Link>
												</div>
												
												<div className="col-md-12 mt-3">
													<button type="submit" onClick={SubmitForm} className="btn btn-primary login-btn" style={{background:headerButtonColor}}>Login</button>
													
												</div>
												<div className="col-md-12">
												<Link to="" className="btn btn-primary login-btn fb" >Login With Facebook</Link>
												
													<Link to={config.baseUrl + 'register'} className="btn btn-primary login-btn reg" >Register Now</Link>
												</div>
											</div>
										</div>
									</div>
									</div>
								</form>
							</div>
						</div>
						<div className="col-lg-3">
							<Rightnav />
						</div>
					</div>
				</div>
			</div>
			
			<Footer />
		</>
	)
	
}

export default Login;
