import React, { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { getPlansAction, getPlanDetailsAction, upgradePlaneAction } from '../Action/action';
import toast, { Toaster } from 'react-hot-toast';
import config from "../coreFIles/config";
import Header from '../directives/header';
import Footer from '../directives/footer';
import Rightnav from '../directives/rightSidebar';
import Leftnav from '../directives/leftSidebar';

const loginData = JSON.parse(Cookies.get('loginSuccessMember') || '[]');
const Domainstyle = (!Cookies.get('domainstyle')) ? [] : JSON.parse(Cookies.get('domainstyle'));
const Upgrade_ad = () => {
    // style
	const timeColor = `#${Domainstyle[0]?.time_color}`;
	const headerButtonColor = `#${Domainstyle[0]?.header_buttoncolor}`;
	const headerMenuColor = `#${Domainstyle[0]?.header_menu}`;
	const headerMenuHoverColor = `#${Domainstyle[0]?.header_menu_hover}`;
	const header_search_text = `#${Domainstyle[0]?.header_search_text}`
	// style end
    const { id } = useParams();
    const [plansList, setPlansList] = useState([]);
    const [selectedOption, setSelectedOption] = useState(0);
    const [validatioError, setvalidatioError] = useState({});
    const [form, setForm] = useState({
        adid: id,
        from_date: '',
        to_date: '',
        planDays: '',
        planAmount: '',
        member_id: loginData?.id,
        plan_id: 0,
    });

    useEffect(() => {
        getPlans();
    }, []);

    const getPlans = async () => {
        try {
            let res = await getPlansAction();
            if (res.success) {
                setPlansList(res.data);
            }
        } catch (error) {
            console.error("Error fetching plans:", error);
        }
    };

    const formatDate = (str) => {
        const date = new Date(str);
        const mnth = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    };

    const adddaysToDate = (days) => {
        const cdate = new Date();
        const result = cdate.setDate(cdate.getDate() + days);
        return formatDate(result);
    };
    const handleChange = async (row) => {
        const { plan_id, days, price } = row;

        setSelectedOption(plan_id);
        if (plan_id !== 0) {
            const fromDate = formatDate(new Date());
          
            setForm({
                ...form,
                plan_id,
                member_id: loginData?.id,
                from_date: fromDate,
                planDays: days,
                planAmount: price,
            });
        }
    };

    const handleFetchError = (error) => {
        toast.error("Failed to fetch plans.");
        console.error("Error fetching plans:", error);
    };

    useEffect(() => {
        getPlans().catch(handleFetchError);
    }, []);


    const validate = () => {
        let planError = form.plan_id ? "" : "Please Select A Plan.";
        setvalidatioError({ planError });
        return !planError;
    };

    const SubmitForm = async (e) => {
        e.preventDefault();
        if (!validate()) return;

        try {
            let res = await upgradePlaneAction({ ...form });

            if (res.success) {
                toast.success(res.msg);
                setTimeout(() => {
					window.location.href = `${config.baseUrl}manage-ads`;
				}, 2000);
            } else {
                toast.error(res.msg);
            }
        } catch (error) {
            toast.error("An error occurred during the upgrade.");
            console.error("Error upgrading plan:", error);
        }
        
    };

    return (
        <>
            <Header />
            <div className="add-section mb-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <Leftnav />
                        </div>
                        <div className="col-lg-6">
                            <Toaster />
                            <div className="classification-section">
                                <h2 className="page-heading" style={{background:headerMenuColor}}>Upgrade Your Ad</h2>
                                <form onSubmit={SubmitForm}>
                                    <div className="table-responsive table-striped">
                                        <table className="table">
                                            <tbody>
                                                <tr className="basicclassified lightbluebg">
                                                    <td colSpan="7" className="planInfo text-center">PREMIUM CLASSIFIED</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="7" className="planInfo">With premium upgrade your listing will stand out. It will always be highlighted in the top section of your chosen category and also appear as featured classified on homepage.</td>
                                                </tr>
                                                <tr className="text-center lightbluebg">
                                                    {plansList.length > 0 ?
                                                        plansList.map((row, index) => (
                                                            <td key={index} className="plan">{row.days} Days<br />${row.price}</td>
                                                        ))
                                                        : ''}
                                                </tr>
                                                <tr className="text-center darkbluebg">
                                                    {plansList.length > 0 ?
                                                        plansList.map((row, index) => (
                                                            <td key={index} className="planInput"><input className="required" type="radio" value={row.plan_id} checked={selectedOption == row.plan_id} onChange={() => handleChange(row)} /></td>
                                                        ))
                                                        : ''}
                                                </tr>



                                                <span className="text-danger">{validatioError.planError}</span>
                                                <tr>
                                                    <td colSpan="7">
                                                        <button type='submit' className="btn btn-sm " id="editbtnid" style={{background:headerButtonColor}}>Upgrade</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <Rightnav />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default memo(Upgrade_ad);
