import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import Header from '../directives/header';
import Footer from '../directives/footer';
import Rightnav from '../directives/rightSidebar';
import Leftnav from '../directives/leftSidebar';
import toast, { Toaster } from 'react-hot-toast';
import banner from '../Assets/BC-newLogos.jpg'

import config from '../coreFIles/config';

const loginData = (!Cookies.get('loginSuccessMember')) ? [] : JSON.parse(Cookies.get('loginSuccessMember'));
const Domainstyle = (!Cookies.get('domainstyle')) ? [] : JSON.parse(Cookies.get('domainstyle'));
const VipmemberShip = () => {
      // style
	const timeColor = `#${Domainstyle[0]?.time_color}`;
	const headerButtonColor = `#${Domainstyle[0]?.header_buttoncolor}`;
	const headerMenuColor = `#${Domainstyle[0]?.header_menu}`;
	const headerMenuHoverColor = `#${Domainstyle[0]?.header_menu_hover}`;
	const header_search_text = `#${Domainstyle[0]?.header_search_text}`
	
	// style end
    useEffect(() => {
        //upgrademembership()
    }, [])

   
    // upgrade account
   
    // image click base url
    const imgclick = () => {
        window.location.href = `${config.baseUrl}`;
    }

    //    button submit
    const handlesubmit = () => {
        if (loginData) {
            toast.success("Kindly log in first to upgrade")
        }
        else {
            console.log("you are login now")
        }
    }

    return (
        <>

            <Header />
          
            <div className="add-section mb-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <Leftnav />
                        </div>
                  
                            <div className="col-lg-6">


                                <div className="classification-section">
                                    <h2 className="page-heading" style={{background:headerMenuColor}}>VIP Membership</h2>

                                    <div className="table-responsive table-striped">
                                        <p>Become a VIP Member for 12 months and you can post as many ads as you like with no restrictions*<u><b>( Excluding 'Personals' section )</b></u> on all our websites with the same login:</p>
                                    </div>
                                    <div className='img'>
                                        <div className='col-lg-12'>
                                            <img id='bannervip' style={{ width: "600px", margin: "10px" }} src={banner} alt='internet error' onClick={imgclick} />
                                        </div>
                                    </div>
                                    <div className="table-responsive table-striped">For only $1499 VIP memberships will assist in helping our network grow , fund new features on the website and you will get priority notification of any coupon deals, special promotions and event invites.

                                        Upgrade today and post as many ads as you like to thousands of weekly visitors!!!
                                        * This offer is not available to advertisers in the 'Personals' section- Please contact us for VIP advertising in this section.</div>

                                </div>
                                <div className='payVip'>
                                <a className="payNow" id='pay-now' onClick={handlesubmit} style={{background:headerButtonColor}} >Pay Now</a>
                                </div>
                            </div> 
                          
                        <div className="col-lg-3">
                            <Rightnav />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default VipmemberShip;