import React, { useEffect, useState } from 'react';

import config from "../coreFIles/config";
import Header from '../directives/header';
import Leftnav from '../directives/leftSidebar';
import Rightnav from '../directives/rightSidebar';
import Footer from '../directives/footer';
import Cookies from 'js-cookie';
import Swal from "sweetalert2";
import {
    deleteadAction,
    adBlockUnBlockAction,
    repostAdsAction,
    bulkdeleteadAction,
    catDetailsAction,
    getSaveSearchHistoryAction

} from '../Action/action';
const Domainstyle = (!Cookies.get('domainstyle')) ? [] : JSON.parse(Cookies.get('domainstyle'));
const Searchhistory = () => {
    
   // style
	const headerButtonColor = `#${Domainstyle[0]?.header_buttoncolor}`;
	const headerMenuColor = `#${Domainstyle[0]?.header_menu}`;
	// style end


    const loginData = Cookies.get('loginSuccessMember')
        ? JSON.parse(Cookies.get('loginSuccessMember'))
        : null;

    const [isLoading, setIsLoading] = useState(true);
    const [adData, setadData] = useState({});
    const [adsList, setAdsList] = useState([]);
    const [selectedAds, setSelectedAds] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        if (!loginData) {
            window.location.href = `${config.baseUrl}`
        }
    }, [loginData]);

    useEffect(() => {
        getMemberAds();
    }, []);

    const getMemberAds = async () => {
        setIsLoading(true);
        try {
            const res = await getSaveSearchHistoryAction({
                memberid: loginData.id,
               
            });
            console.log(res)
            if (res.data) {
                setAdsList(res.data);
                console.log(res.data)
            }
        } catch (error) {
            console.error(error);
            Swal.fire("Error", "Could not load ads.", "error");
        } finally {
            setIsLoading(false);
        }
    };



    return (
        <>
            <Header />
            <div className="add-section mb-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <Leftnav />
                        </div>
                        <div className="col-lg-6">
                            <div className="classification-section">
                                <h2 className="page-heading" style={{background:headerMenuColor}}>Search History</h2>
                                <div className="mt-3">
                                    <div className="table-responsive table-striped">
                                        <table className="table">
                                            <tbody>
                                                {isLoading ? (
                                                    <tr>
                                                        <td colSpan="7">
                                                            <div className="loaderDiv">
                                                                <img className="loaderImg" src={`${config.baseUrl}img/loader.gif`} alt="Loading..." height={50} width={50} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : adsList.length > 0 ? (
                                                    adsList.map((row,index) => (
                                                        <div key={index}  className= 'section-listing' id="searchhis">
                                                            <div className="main-item">
                                                                <div className="fast-block">
                                                                    <img
                                                                        src={row.addImg ? config.apiUrl + row.addImg : config.baseUrl + "img/noImage.png"}
                                                                        alt={row.title || 'Ad image'}
                                                                        style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                                                                        onError={(e) => { e.target.onerror = null; e.target.src = config.baseUrl + "img/noImage.png"; }}
                                                                    />
                                                                </div>
                                                                <div className="second-block">
                                                                    <h4> {row.title}</h4>

                                                                    {row.sell_price != 0 && row.sell_price != '' ? (
                                                                        <>
                                                                            <p><span><i className="fa-solid fa-dollar-sign"></i></span> ${row.sell_price}</p>
                                                                        </>
                                                                    ) : ''
                                                                    }


                                                                    <p><span><i className="fa-solid fa-location-dot"></i></span> {row.area} ({row.suburb})</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="7">No search history found.</td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <Rightnav />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Searchhistory;
