import React, { useEffect,useState } from 'react'
import { Link } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import config from "../coreFIles/config";
import Header from '../directives/header'
import Leftnav from '../directives/leftSidebar'
import Rightnav from '../directives/rightSidebar'
import Footer from '../directives/footer'
import Cookies from 'js-cookie'
import { getContentAction } from '../Action/action';

const Domainstyle = (!Cookies.get('domainstyle')) ? [] : JSON.parse(Cookies.get('domainstyle'));
const About = () => {
	// style
	
	const headerButtonColor = `#${Domainstyle[0]?.header_buttoncolor}`;
	const headerMenuColor = `#${Domainstyle[0]?.header_menu}`;
	const headerMenuHoverColor = `#${Domainstyle[0]?.header_menu_hover}`;
	const header_search_text = `#${Domainstyle[0]?.header_search_text}`
	
	
	// style end	
	const [content, setcontent] = useState({});
	const [spinloader, setspinloader] = useState(0);
	useEffect(() => {
		setspinloader(1)		
		getcontentAPI();
	},[])
	const getcontentAPI = async () => {
		let res = await getContentAction({contentId:1});
		if (res.success) {
			setcontent(res.data);
		}
		setspinloader(0)
	};	
	
	
	
	
	
	
	return(
		<>
			<Header />
			
			<div className="add-section mb-3">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Leftnav />
						</div>
						<div className="col-lg-6">
							<Toaster />							
							<div className="classification-section">						
								 {spinloader === 0 ? (
								  <>
									<h2 className="page-heading" style={{background:headerMenuColor}}>{ content?.title }</h2>
									<div dangerouslySetInnerHTML={{ __html: content?.content }}></div>
								  </>
								) : (							
								  <div className="loaderDiv">
									<img className="loaderImg" src={config.baseUrl + "img/loader.gif"} height={50} width={50} />
								  </div>
								)}

							</div>
						</div>
						<div className="col-lg-3">
							<Rightnav />
						</div>
					</div>
				</div>
			</div>
			
			<Footer />
		</>
	)
	
}

export default About;
