import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import config from '../coreFIles/config'
import { getsidebarMenuAction, getbannerAdsAction } from '../Action/action';

const Rightsidebar = () => {
	const [bannerAds, setbannerAds]	= useState({});
	
	
	useEffect(() => {
		getbannerAds();
	}, [])
	
	const getbannerAds = async () => {
		let res = await getbannerAdsAction({adColumn:'R'});
		if (res.success) {
			setbannerAds(res.data);
		}
	};
	
	return (
		<>
			<div className="right-section">
				<div className="map-section d-none">
					<img src={config.baseUrl + "img/map-img.png"} alt='map' />
				</div>
				<div className="towblock">
					{bannerAds.length > 0 ? (
						bannerAds.map((row) => (
							<div className="heroimg">
								{row.ad_type == 'img' ?
									<Link to={row.ad_url} target='_blank'>
										<img src={config.bannersUrl + row.ad_file} alt={row.ad_title} />
									</Link>
								: ''}
							</div>
						))
					) : ''}
				</div>
			</div>
		</>
	)
}

export default Rightsidebar;
