import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import config from "../coreFIles/config";
import Header from '../directives/header'
import Leftnav from '../directives/leftSidebar'
import Rightnav from '../directives/rightSidebar'
import Footer from '../directives/footer'
import Cookies from 'js-cookie'
import { getCountriesAction, getSearchSuburbAction, getMemberDetailsAction, updateMemberProfileAction, updateMemberpasswordAction } from '../Action/action';
const loginData = (!Cookies.get('loginSuccessMember')) ? [] : JSON.parse(Cookies.get('loginSuccessMember'));

const Profile = () => {

	const [SuburbsearchResults, setSuburbsearchResults] = useState({});
	const [countriesList, setCountriesList] = useState({});
	const [suburb, setsuburb] = useState('');
	const [spinloader, setspinloader] = useState(0);
	const [pwdspinloader, setpwdspinloader] = useState(0);
	const [memberDetails, setMemeberDetails] = useState({});
	const [validationError, setvalidationError] = useState({});
	const [pwdvalidationError, setpwdvalidationError] = useState({});
	const [form, setForm] = useState({
		memeber_id: loginData?.id,
		password: '',
		cpassword: ''
	});

	if (!Cookies.get('loginSuccessMember')) {
		window.location.href = `${config.baseUrl}`;
	}

	useEffect(() => {
		getCountries();
		getMemberProfileAPI();
	}, [])

	const getCountries = async (e) => {
		let res = await getCountriesAction();
		if (res.success) {
			setCountriesList(res.data)
		}
	}

	const getMemberProfileAPI = async () => {
		let res = await getMemberDetailsAction({ id: loginData?.id });
		if (res.success) {
			setMemeberDetails(res.data);
			let str = res.data?.suburb + '-' + res.data?.state + '-' + res.data?.zip;
			setsuburb(str);
			setMemeberDetails((old) => {
				return { ...old, 'suburb': str };
			});

		}
	};

	const inputpasswordHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}

	const inputHandler = async (e) => {
		const { name, value } = e.target
		setMemeberDetails((old) => {
			return { ...old, [name]: value }
		})
	}
	const inputPhoneHandler = async (e) => {
		const { name, value } = e.target
		if (/^\d{0,10}$/.test(value)) {
			setMemeberDetails((old) => {
				return { ...old, [name]: value }
			})
		}
	}
	const handleKeyDown = (e) => {
		if (!/^\d$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
			e.preventDefault();
		}
	};

	const handleSelect = async (e) => {
		const { name, value } = e.target
		setMemeberDetails((old) => {
			return { ...old, [name]: value }
		})
	}

	const checkboxHandler = (e) => {
		const { name, value } = e.target;
		if (e.target.checked) {
			setMemeberDetails((old) => {
				return { ...old, [name]: value };
			});
		} else {
			setMemeberDetails((old) => {
				return { ...old, [name]: 0 };
			});
		}
	};

	const SearchSuburb = async (str) => {
		try {
			setsuburb(str);
			if (str != '') {
				let res = await getSearchSuburbAction({ str: str });
				setSuburbsearchResults(res.data);

				setMemeberDetails((old) => {
					return { ...old, 'suburb': str };
				});
			}
			else {
				setSuburbsearchResults({});
			}
		}
		catch (error) {
			console.error(error);
		}
	};

	const handleSelectSuburb = (row) => {
		setsuburb(row);
		setMemeberDetails((old) => {
			return { ...old, 'suburb': row };
		});
		setSuburbsearchResults({});
	};

	function validate() {
		let fnameError = "";
		let surnameError = "";
		let genderError = "";
		let countryError = "";
		let contact_noError = "";

		if (memberDetails.fname === '') {
			fnameError = "First name is required."
		}
		if (memberDetails.lname === '') {
			surnameError = "Surname is required."
		}
		if (memberDetails.sex === '') {
			genderError = "Gender is required."
		}
		if (memberDetails.country === '') {
			countryError = "Country is required."
		}
		if (memberDetails.contact_no === '') {
			contact_noError = "Phone is required."
		}

		if (fnameError || surnameError || genderError || countryError || contact_noError) {
			setvalidationError({
				fnameError, surnameError, genderError, countryError, contact_noError
			})
			return false
		} else {
			setvalidationError({
				fnameError, surnameError, genderError, countryError, contact_noError
			})
			return true
		}
	}

	function validatepwd() {
		let passwordError = "";
		let cpasswordError = "";

		if (form.password === '') {
			passwordError = "Password is required."
		}
		if (form.cpassword === '') {
			cpasswordError = "Confirm password is required."
		}

		if (form.password != '' && form.cpassword != '') {
			if (form.password != form.cpassword) {
				cpasswordError = "Confirm password not matched."
			}
		}


		if (passwordError || cpasswordError) {
			setpwdvalidationError({
				passwordError, cpasswordError
			})
			return false
		} else {
			setpwdvalidationError({
				passwordError, cpasswordError
			})
			return true
		}
	}

	const SubmitForm = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {

		}
		else {
			setspinloader(1);
			let res = await updateMemberProfileAction(memberDetails);
			if (res.success) {
				toast.success(res.msg);
				const log = Cookies.set('loginSuccessMember', JSON.stringify(res.data));
				setMemeberDetails(log)
				console.log(log)
				getMemberProfileAPI();
				setspinloader(0);
			}
			else {
				toast.error(res.msg);
				setspinloader(0);
			}
		}
	}

	const SubmitpasswordForm = async (e) => {
		e.preventDefault()
		const isValid = validatepwd();
		if (!isValid) {

		}
		else {
			setpwdspinloader(1);
			let res = await updateMemberpasswordAction(form);
			if (res.success) {
				toast.success(res.msg);
				setForm((old) => {
					return { ...old, 'password': '' }
				})
				setForm((old) => {
					return { ...old, 'cpassword': '' }
				})
				setpwdspinloader(0);
			}
			else {
				toast.error(res.msg);
				setpwdspinloader(0);
			}
		}
	}

	return (
		<>
			<Header />

			<div className="add-section mb-3">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Leftnav />
						</div>
						<div className="col-lg-6">
							<Toaster />

							<div className="classification-section">
								<h2 className="page-heading"> Update Profile </h2>

								<form action='' method='post'>
									<div className="bondiclassified-fromsection">
										<div className="catagoryfrom">
											<div className="row" >
												<div className="col-md-12 col-sm-12">
													<div className="form-group">
														<label for="fname">First Name <span className='text-danger'>*</span></label>
														<input type='text' className='form-control' name='fname' id='fname' onChange={inputHandler} value={memberDetails?.fname} />
														<span className="text-danger">{validationError.fnameError}</span>
													</div>
												</div>

												<div className="col-md-12 col-sm-12">
													<div className="form-group">
														<label for="lname">Surname <span className='text-danger'>*</span></label>
														<input type='text' className='form-control' name='lname' id='lname' onChange={inputHandler} value={memberDetails?.lname} />
														<span className="text-danger">{validationError.surnameError}</span>
													</div>
												</div>

												<div className="col-md-12 col-sm-12">
													<div className="form-group">
														<label for="sex">Gender <span className='text-danger'>*</span></label>
														<select className='form-control' name='sex' id='sex' onChange={handleSelect} value={memberDetails?.sex}>
															<option value=""> -- Please Select -- </option>
															<option value="0">Male</option>
															<option value="1">Female</option>
														</select>
														<span className="text-danger">{validationError.genderError}</span>
													</div>
												</div>

												<div className="col-md-12 col-sm-12">
													<div className="form-group">
														<label for="email">Email <span className='text-danger'>*</span></label>
														<input type='text' className='form-control' name='email' id='email' onChange={inputHandler} value={memberDetails?.email} disabled />
														<span className="text-danger">{validationError.emailError}</span>
													</div>
												</div>

												<div className="col-md-12 col-sm-12">
													<div className="form-group">
														<label for="country">Where do you live? <span className='text-danger'>*</span></label>
														<select className='form-control' name='country' id='country' onChange={handleSelect} value={memberDetails?.country}>
															<option value=""> Please Select a Country </option>
															{countriesList.length > 0 ?
																countriesList.map((row) => (
																	<option value={row.country_code}>{row.country_name}</option>
																))
																: ''}
														</select>
														<span className="text-danger">{validationError.countryError}</span>
													</div>
												</div>

												{memberDetails?.country == 'AU' ?
													<div className="col-md-12 col-sm-12">
														<div className="form-group">
															<label for="member_suburb">Suburb</label>
															<div className="suburbtip mb-3"><b>Follow Instruction:</b><br />
																1. Please type first few letters of your suburb.<br />
																2. Wait for suggestions.<br />
																3. Select your suburb.
															</div>
															<input type="text" className="form-control" onChange={(e) => SearchSuburb(e.target.value)} id="member_suburb" placeholder="Suburb.." value={suburb} />
															{SuburbsearchResults.length > 0 ? (
																<ul className="suburbList">
																	{SuburbsearchResults.map((row, index) => (
																		<li key={index} onClick={() => handleSelectSuburb(row.rowlocation)}>
																			{row.rowlocation}
																		</li>
																	))}
																</ul>
															) : null}
														</div>
													</div>
													: ''}

												<div className="col-md-12 col-sm-12">
													<div className="form-group">
														<label for="contact_no">Phone Number <span className='text-danger'>*</span></label>
														<input type='text' className='form-control' name='contact_no' id='contact_no' onChange={inputPhoneHandler} maxLength={10} onKeyDown={handleKeyDown} value={memberDetails?.contact_no} />
														<span className="text-danger">{validationError.contact_noError}</span>
													</div>
												</div>

												<div className="col-md-12 col-sm-12">
													<div className="form-group">
														<label for="website">Have a website or blog?</label>
														<input type='text' className='form-control' name='website' id='website' onChange={inputHandler} value={memberDetails?.website} />
													</div>
												</div>
												<div className="col-md-12 mt-3 pdb-20">
													{spinloader == '0' ? (
														<>
															{memberDetails?.membership_type == 2 && (
																<>
																	<button
																		type="button"
																		onClick={SubmitForm}
																		className="btn btn-primary login-btn"
																	>
																		Upgrade to VIP
																	</button>
																	&nbsp;&nbsp;
																</>
															)}
															<button
																type="button"
																onClick={SubmitForm}
																className="btn btn-primary login-btn"
															>
																Update Profile
															</button>
														</>
													) : (
														<button disabled className="btn btn-primary login-btn">
															Updating.. <i className="fa fa-spinner fa-spin validat"></i>
														</button>
													)}
												</div>


											</div>
										</div>
									</div>
								</form>
								<h2 className="mt-3 page-heading"> Change Password </h2>
								<form action='' method='post'>
									<div className="bondiclassified-fromsection">
										<div className="catagoryfrom">
											<div className="row" >
												<div className="col-md-12 col-sm-12">
													<div className="form-group">
														<label for="fname">Password <span className='text-danger'>*</span></label>
														<input type='password' className='form-control' name='password' id='password' onChange={inputpasswordHandler} value={form.password} />
														<span className="text-danger">{pwdvalidationError.passwordError}</span>
													</div>
												</div>

												<div className="col-md-12 col-sm-12">
													<div className="form-group">
														<label for="lname">Confirm Password <span className='text-danger'>*</span></label>
														<input type='password' className='form-control' name='cpassword' id='cpassword' onChange={inputpasswordHandler} value={form.cpassword} />
														<span className="text-danger">{pwdvalidationError.cpasswordError}</span>
													</div>
												</div>
												<div className="col-md-12 mt-3 pdb-20">
													{pwdspinloader == '0' ? (
														<>
															<button
																type="button"
																onClick={SubmitpasswordForm}
																className="btn btn-primary login-btn"
															>
																Change Password
															</button>
														</>
													) : (
														<button disabled className="btn btn-primary login-btn">
															Changing.. <i className="fa fa-spinner fa-spin validat"></i>
														</button>
													)}
												</div>


											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div className="col-lg-3">
							<Rightnav />
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	)

}

export default Profile;
