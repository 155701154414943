import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { InputMask } from '@react-input/mask';
import toast, { Toaster } from 'react-hot-toast';
import config from "../coreFIles/config";
import JoditEditor from "jodit-react";
import Header from '../directives/header'
import Leftnav from '../directives/leftSidebar'
import Rightnav from '../directives/rightSidebar'
import Footer from '../directives/footer'
import Cookies from 'js-cookie'
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2';
import "react-datepicker/dist/react-datepicker.css";


import { getCategoriesAction, getnavChildCategoriesAction, getSearchSuburbAction, getPlansAction, getPlanDetailsAction, updateAdAction, getAdDetailAction, getChildCatsAction, getAdPaymentHistoryAction, getadImgesAction, deladimgAction } from '../Action/action';

const Domainstyle = (!Cookies.get('domainstyle')) ? [] : JSON.parse(Cookies.get('domainstyle'));
const EditAd = () => {	
	
	// style
	const timeColor = `#${Domainstyle[0]?.time_color}`;
	const headerButtonColor = `#${Domainstyle[0]?.header_buttoncolor}`;
	const headerMenuColor = `#${Domainstyle[0]?.header_menu}`;
	const headerMenuHoverColor = `#${Domainstyle[0]?.header_menu_hover}`;
	const header_search_text = `#${Domainstyle[0]?.header_search_text}`
	
	
	// style end
	if (!Cookies.get('loginSuccessMember')) {
		window.location.href = `${config.baseUrl}`;
	}
	const { id }								= useParams();
	const loginData = (!Cookies.get('loginSuccessMember')) ? [] : JSON.parse(Cookies.get('loginSuccessMember'));	
	const editorHeight 							= '500px';
	const [spinloader, setspinloader] 			= useState(0);
	const [categoriesList, setCategoriesList]	= useState({});
	const [plansList, setPlansList]				= useState({});
	const [childcatsList, setchildCats]			= useState([]);
	const [allcatChilds, setallcatChilds]			= useState([]);
	const [SuburbsearchResults, setSuburbsearchResults]	= useState({});
	const [suburb, setsuburb] 					= useState('');
	const [validatioError, setvalidatioError]	= useState({});
	const [currentDate, steCurrentDate]			= useState(new Date());
	const [selectedOption, setSelectedOption] 	= useState(0);	
	const [addata, setaddata] = useState({});
	const [adpaymentdata, setadpaymentdata] = useState([]);
	const [adimagesdata, setadImagesdata] = useState({});
	const [imgdata, setimgdata] = useState({});	
	useEffect(() => {
		getAdDetails();
        getAdPaymentHistory();
        getAdImagesAPI();
		getCategories();		
	}, [])

	const getCategories = async (e) => {
		let res = await getCategoriesAction();
		if(res.success){
			setCategoriesList(res.data)
		}
	}
	
	const getAdDetails = async () => {
		let res = await getAdDetailAction({id:id});
		if (res.success) {
			setaddata(res.data);
			getChildCats(res.data);  
		}
		else
		{
			setaddata({});	
		}
	}
	
	const getChildCats = async(data) => {		
		let res = await getChildCatsAction({id:data.parent_id});
		if (res.success) {
			setchildCats(res.data);
		}
		else{
			setchildCats({});
		}		
	};
    
	const getAdPaymentHistory = async () => {
		let res = await getAdPaymentHistoryAction({ id: id });
		if (res.success) {
			setadpaymentdata(res.data);
		}
		else {
			setadpaymentdata({});
		}
	}
	
	const getAdImagesAPI = async () => {
		let res = await getadImgesAction({id:id});
		if (res.success) {
			setadImagesdata(res.data);
		}
		else
		{
			setadImagesdata({});	
		}
	}
	
	
	

	const SearchSuburb = async (str) => {
		try {
			setsuburb(str);
			if(str != ''){
				let res = await getSearchSuburbAction({str:str});
				setSuburbsearchResults(res.data);
				
				setaddata((old) => {
					return { ...old, 'suburb': str };
				});
			}
			else{
				setSuburbsearchResults('');
			}
		}
		catch (error) {
			console.error(error);
		}
	};

	const handleSelectSuburb = (row) => {
		setsuburb(row);
		
		setaddata((old) => {
			return { ...old, 'suburb': row };
		});
		
		setSuburbsearchResults({});
	};

	const inputHandler = async (e) => {
		const { name, value } = e.target
		setaddata((old) => {
			return { ...old, [name]: value }
		})
	}

	const inputfileHandler = (e) => {
		e.preventDefault()
		const files = Array.from(e.target.files);
		
	   	setaddata((old) => {
			return { ...old, ['images'] : files }
	   	})
	};

	function formatDate(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

	function adddaysToDate(days) {
		var cdate	= new Date();
		var result	= cdate.setDate(cdate.getDate() + days);
		var newdate	= formatDate(result);
		
        return newdate;
    }

	const whatsonDate = e => {
        let whtsonDate = formatDate(e);
		setaddata((old) => {
			return { ...old, 'date_whatson': whtsonDate }
		})
    }

	const handleKeyPress = (e) => {
		// Allow only numeric values (0-9) and certain control keys
		const isValidInput = /^[0-9\b]+$/.test(e.key);
		
		if (!isValidInput) {
			e.preventDefault();
		}
	};

	const handleSelect = async (e) => {
		const { name, value } = e.target
		setaddata((old) => {
			return { ...old, [name]: value }
		})
	}

	const checkboxHandler = (e) => {
		const { name, value } = e.target;
		if (e.target.checked) {
			setaddata((old) => {
				return { ...old, [name]: 1 };
			});
		} else {
			setaddata((old) => {
				return { ...old, [name]: 0 };
			});
		}
	};

	const descriptionHandler = async (e) => {
		setaddata((old) => {
		  return { ...old, 'discription': e }
		})
	}

	const inputPhoneHandler = async (e) => {
		const { name, value } = e.target
		
		  setaddata((old) => {
				return { ...old, [name]: value }
			})
				
	}
	const handleKeyDown = (e) => { 
    if (!/^\d$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
      e.preventDefault();
    }
  };

	function validate() {
		let parentError = "";
		let categoryError = "";
		let titleError = "";
		let descriptionError = "";
		let suburbError = "";
		let areaError = "";
		let statusError = "";

		if (setaddata.parent_id === "") {
			parentError = "Category is required.";
		}
		
		if (setaddata.category_id === "") {
			categoryError = "Sub Category is required.";
		}
		
		if (setaddata.title === "") {
			titleError = "Ad title is required.";
		}
		
		if (setaddata.description === "") {
			descriptionError = "Description is required.";
		}
		
		if (setaddata.status === "") {
			statusError = "Status is required.";
		}
		
		if (setaddata.suburb === "") {
			suburbError = "Suburb is required.";
		}
		
		if (setaddata.area === "") {
			areaError = "Area is required.";
		}

		if (titleError || parentError || categoryError || descriptionError || statusError || suburbError || areaError) {
			setvalidatioError({
				titleError,parentError,categoryError,descriptionError,statusError,suburbError,areaError });
			return false;
		}
		else {
			setvalidatioError({
			titleError,parentError,categoryError,descriptionError,statusError,suburbError,areaError });
			return true;
		}
	}

	const SubmitForm = async (e) => {
		e.preventDefault()
		const isValid = validate();
		
		if (!isValid) {

		}
		else {
			setspinloader(1);
			let res = await updateAdAction(addata);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}manage-ads`;
				}, 2000);
			}			 
			else {
				setspinloader(0);
				toast.error(res.msg);
			}
		}
	}
	
	const deladimg = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this ad image!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Deleted it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deladimgAction({ id: id });
        if (res.success) { 
			 getAdImagesAPI();                  
          Swal.fire("Deleted!", res.msg, "success");
        } else {
          Swal.fire("Failed!", res.msg, "error");          
        }
      }
    });
  };  

	return(
		<>
			<Header />

			<div className="add-section mb-3">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Leftnav />
						</div>
						<div className="col-lg-6">
							<Toaster />

							<div className="classification-section">
								<h2 className="page-heading" style={{background:headerMenuColor}}> Post My Classified </h2>

								<form onSubmit={SubmitForm}>			

									<div className="bondiclassified-fromsection">
										<div className="catagoryfrom">
											<div className="row">
												<div className="col-md-6 col-sm-12">
													<div className="form-group">
														<label for="parent_id">Category</label>
														<select className="form-control" id="parent_id" disabled value={addata?.parent_id}>
															<option value='0'>Select</option>
															{categoriesList.length > 0 ?
																categoriesList.map((row, index) => (
																	<option key={index} value={row.cat_id}>{row.cat_name}</option>
																))
															: ''}
														</select>
														<span className="text-danger">
															{validatioError.parentError}
														</span>
													</div>
												</div>
												
												<div className="col-md-6 col-sm-12">
													<div className="form-group">
														<label for="category_id">Category</label>
														<select className="form-control" id="category_id" name="category_id" onChange={handleSelect} value={addata?.category_id}>
															<option>Select</option>
															{childcatsList.length > 0 ?
																childcatsList.map((row, index) => (
																	<option key={index} value={row.cat_id}>{row.cat_name}</option>
																))
															: ''}
														</select>
														<span className="text-danger">
															{validatioError.categoryError}
														</span>
													</div>
												</div>
												
												<div className="col-md-12 col-sm-12">
													<div className="form-group">
														<label for="title">Title</label>
														<input type="text" className="form-control" id="title" name='title' onChange={inputHandler} value={addata?.title} />
														<span className="text-danger">
															{validatioError.titleError}
														</span>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-group">
														<label for="desctiption">Description</label>
														 &nbsp; <span className="text-danger">
															{validatioError.descriptionError}
														</span>
														<JoditEditor onChange={descriptionHandler} value={addata?.discription} name="discription" id="discription" style={{ height: editorHeight }} className="" placeholder="Enter description" />
													</div>
												</div>
												
												<div className="col-md-6">
													<div className="form-group">
														<label for="suburb">Suburb</label>
														<input type="text" name="suburb" className="form-control" id="suburb"  onChange={(e) => SearchSuburb(e.target.value)} value={addata?.suburb!=="" ? addata?.suburb:suburb } autoComplete="off" />
														
														<span className="text-danger">
															{validatioError.suburbError}
														</span>
														{SuburbsearchResults.length > 0 ? (
															<ul className="suburbList">
																{SuburbsearchResults.map((row) => (
																	<li key={row.id} onClick={() => handleSelectSuburb(row.rowlocation)}>
																		{row.rowlocation} 
																	</li>
																))}
															</ul>
														) : null}
													</div>
												</div>
												
												<div className="col-md-6">
													<div className="form-group">
														<label for="area">Area</label>
														
														<select name='area' className="form-control" id='areaError' onChange={handleSelect} value={addata?.area}>
															<option value="">Select</option>  
															<option value="Australia Wide">Australia Wide</option>
															<option value="NSW - Sydney">NSW - Sydney</option>
															<option value="NSW - Regional">NSW - Regional</option>
															<option value="QLD - Brisbane">QLD - Brisbane</option>
															<option value="QLD - Regional">QLD - Regional</option>
															<option value="VIC - Melbourne">VIC - Melbourne</option>
															<option value="VIC - Regional">VIC - Regional</option>
															<option value="WA - Perth">WA - Perth</option>
															<option value="WA - Regional">WA - Regional</option>
															<option value="SA - Adelaide">SA - Adelaide</option>
															<option value="SA - Regional">SA - Regional</option>
															<option value="NT - Darwin">NT - Darwin</option>
															<option value="NT - Regional">NT - Regional</option>
															<option value="TAS - Hobart">TAS - Hobart</option>
															<option value="TAS - Regional">TAS - Regional</option>
														</select>
														
														<span className="text-danger">
															{validatioError.areaError}
														</span>
													</div>
												</div> 
											</div>
										</div>
										
										<div className="catagory-attchment">
											<div className="col-md-12">
												<div className="form-group">
													<label for="ad_image">Drag & Drop your images here OR simply click the "Select Files" button to upload.</label>
													<input type="file" className="form-control-file" id="ad_image" name='ad_image'  onChange={inputfileHandler} multiple />
												</div>
											</div>
										</div>
										<div className="catagory-attchment">
											<div className="col-md-12">
												<div className="row ad-image-gallery">
													  {adimagesdata.length > 0 ?
														  adimagesdata.map((image, index) => (
														<div id={'img_'+image.imgId} key={index} className="col-md-4 ad-img">
														  <img src={config.apiUrl + image.addImg} alt={`Image ${index + 1}`} />
														  <span className="del" onClick={() => deladimg(image.imgId)}>X</span>
														</div>
													  ))
													  :''}
													</div>
											</div>
										</div>
										
										
										
										<div className="catagoryfrom">
											<div className="row">
												{ parseInt(addata?.parent_id)===parseInt(config.cat_per) ?
													<>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">
																	Age Group
																</label>
																
																<select name='age' className="form-control" id='age_groupError' onChange={handleSelect} value={addata?.age}>
																	<option value="">Select</option>
																	<option value="18-22">18-22</option>
																	<option value="23-26">23-26</option>
																	<option value="27-30">27-30</option>
																	<option value="31-36">31-36</option>
																	<option value="37-42">37-42</option>
																	<option value="43-50">43-50</option>
																	<option value="50-60">50-60</option>
																	<option value="over 61">over 61</option>
																</select>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">
																	Gender
																</label>
																
																<select name='sex' className="form-control" id='genderError' onChange={handleSelect} value={addata?.sex}>
																	<option value="">Select</option>
																	<option value="0">Male</option>
																	<option value="1">Female</option>
																</select>
															</div>
														</div>
													</>
												: ''}
												{parseInt(addata?.parent_id)===parseInt(config.cat_real) ?
													<>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">Property Type</label>
																
																<select name='property_type' className="form-control" id='property_typeError' onChange={handleSelect} value={addata?.property_type}>
																	<option value="">Select</option>
																	<option value="All">All</option>
																	<option value="Studio">Studio</option>
																	<option value="Apartment">Apartment</option>
																	<option selected="selected" value="House">House</option>
																	<option value="Rural">Rural</option>
																</select>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">Bedrooms</label>
																
																<select name='bed_rooms' className="form-control" id='bedroomsError' onChange={handleSelect} value={addata?.bed_rooms}>
																	<option value="">Select</option>
																	<option value="All">All</option>
																	<option value="1 or more">1 or more</option>
																	<option value="2 or more">2 or more</option>
																	<option value="3 or more">3 or more</option>
																	<option value="4 or more">4 or more</option>
																</select>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">Unit/Flat</label>
																
																<input type="text" name='unit_flat' className="form-control" id='unit_flatError'  onChange={inputHandler}value={addata?.unit_flat} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">Street No</label>
																
																<input type="text" name='street_number' className="form-control" id='street_noError'  onChange={inputHandler} value={addata?.street_number} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">Street Name</label>
																
																<input type="text" name='street_name' className="form-control" id='street_nameError'  onChange={inputHandler} value={addata?.street_name}/>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">Price</label>
																
																<input type="text" name='sell_price' className="form-control" id='priceError'  onChange={inputHandler} onKeyPress={handleKeyPress} value={addata?.sell_price} />
															</div>
														</div>
													</>
												: ''}
												{parseInt(addata?.parent_id)===parseInt(config.cat_buy) ?
													<>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">Price</label>
																
																<input type="text" name='sell_price' className="form-control" id='priceError'  onChange={inputHandler} onKeyPress={handleKeyPress} value={addata?.sell_price} />
															</div>
														</div>
													</>
												: ''}
												{parseInt(addata?.parent_id)===parseInt(config.cat_whats) ?
													<>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">When is the Event?</label>
																
																<DatePicker onChange={whatsonDate}  placeholder ="Select Date" autoComplete="off" name="date_whatson" id="whatson_dateError" className='form-control' value={formatDate(addata?.date_whatson)} selected={addata?.date_whatson ? new Date(addata?.date_whatson) : new Date()} minDate={currentDate}  />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">
																	Day/Night Event?
																</label>
																
																<select name='time_whatson' className="form-control" id='event_typeError' onChange={handleSelect} value={addata?.time_whatson}>
																	<option value="">Select</option>
																	<option value="Day">Day</option>
																	<option value="Night">Night</option>
																</select>
															</div>
														</div>
													</>
												: ''}
												{parseInt(addata?.parent_id)===parseInt(config.cat_jobs) ?
													<>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">Work Type</label>
																
																<select name='job_work_type' className="form-control" id='work_typeError'  onChange={handleSelect} value={addata?.job_work_type}>
																	<option value="">Select Work Type</option>
																	<option value="All">All</option>
																	<option value="Full Time">Full Time</option>
																	<option value="Part Time">Part Time</option>
																	<option value="Contract/Temp">Contract/Temp</option>
																	<option value="Freelance">Freelance</option>
																</select>
															</div>
														</div>
													</>
												: ''}
												{parseInt(addata?.parent_id)===parseInt(config.cat_business) ?
													<>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">ABN (Australian Business Number)</label>
																<input className="form-control" type="text" name="abn" id='abnError' onChange={inputHandler} placeholder="Enter Australian Business Number" onKeyPress={handleKeyPress} value={addata?.abn} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label className="">Accreditation</label>																
																<input className="form-control" type="text" name="accreditation" id='accreditationError' onChange={inputHandler} placeholder="Enter Accreditation" value={addata?.accreditation} />
															</div>
														</div>
													</>
												:''}
												
												<div className="col-md-6 col-sm-6">
													<div className="form-group">
														<label for="phone">Phone</label>
														
														<InputMask
                                                            type='text'
                                                            className='form-control'
                                                            name='phone'
                                                            id='phone'
															value={addata?.phone}
                                                            onChange={inputPhoneHandler}
															maxLength={10}
                                                            onKeyDown={handleKeyDown}
                                                            mask="____ ___ ___" 
                                                            replacement={{ _: /\d/ }}
                                                            placeholder="____ ___ ___"
                                                            
                                                        />
													</div>
												</div>
												
												<div className="col-md-6 col-sm-6">
													<div className="form-group">
														<label for="tags">Tags</label>
														<input type="text" className="form-control" id="tags" name="tags" onChange={inputHandler} value={addata?.tags} />
													</div>
												</div>
												
												<div className="col-md-6 col-sm-6">
													<div className="form-group">
														<label for="youtube_url">Youtube Video URL Code</label>
														<input type="text" className="form-control mb-2" id="youtube_url" name='youtube_url' onChange={inputHandler} value={addata?.youtube_url} />
														<img src={config.baseUrl + "img/urlimg.jpg"} alt='ad-yt-video' />
													</div>
												</div>
												
												<div className="col-md-6 col-sm-6">
													<div className="form-group hideblock">
														<label for="formGroupExampleInput">Hide My ID</label><br />
														<input type="checkbox" id="hide_my_id" name="hide_my_id" value="1" onChange={checkboxHandler} checked={addata.hide_my_id === 1} />
													</div>
												</div>
												
												<div className="col-md-12 text-center mt-3 pdb-20 submitsection">
												{spinloader == '0' ?
													<button type="submit" className="btn btn-primary submitbtn" style={{background:headerButtonColor}}>Submit</button>
													:
													<button disabled className='btn btn-primary submitbtn' style={{background:headerButtonColor}} >Submiting.. <i className="fa fa-spinner fa-spin validat"></i></button>
													}
													
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div className="col-lg-3">
							<Rightnav />
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	)

}

export default EditAd;
