import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { InputMask } from '@react-input/mask';
import config from "../coreFIles/config";
import Header from '../directives/header'
import Leftnav from '../directives/leftSidebar'
import Rightnav from '../directives/rightSidebar'
import Footer from '../directives/footer'
import Cookies from 'js-cookie'
import { getContentByKeywordAction,getCountriesAction, getSearchSuburbAction, createMemberAction } from '../Action/action';


	const Register = () => {
	const [tncData, setTncData]							= useState({});
	const [SuburbsearchResults, setSuburbsearchResults]	= useState({});
	const [countriesList, setCountriesList]	= useState({});
	const [suburb, setsuburb]							= useState('');
	const [spinloader, setspinloader] = useState(0);  
	const [form, setForm] = useState({
		memberType			: '1',
		is_agree_terms		: '',
		member_fname		: '',
		member_surname		: '',
		member_gender		: '',
		member_phone		: '',
		member_email		: '',
		member_webaddress	: '',
		member_country		: '',
		member_suburb		: '',
		member_mtype		: 1,
		member_password		: '',
		member_cpassword	: ''
	});
	
	const [validatioError, setvalidatioError] = useState({});
	const [step1error, setStep1Error] = useState({});
	const [step2error, setStep2Error] = useState({});
	
	if (Cookies.get('loginSuccessMember')) {
		window.location.href = `${config.baseUrl}`;
	}
	
	useEffect(() => {
		getTncData();
		getCountries();
	}, [])
	
	const getTncData = async (e) => {
        let res = await getContentByKeywordAction({keyword:'terms-and-conditions'});
        if(res.success){
            setTncData(res.data)
        }
    }
	
	const getCountries = async (e) => {
        let res = await getCountriesAction();
        if(res.success){
            setCountriesList(res.data)
        }
    }
	
	const inputHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}
	const inputPhoneHandler = (e) => {
        const { name, value } = e.target;
        setForm((old) => ({ ...old, [name]: value }));
        
    };

	const handleKeyDown = (e) => { 
    if (!/^\d$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
      e.preventDefault();
    }
  };

	
	const handleSelect = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}
	
	const checkboxHandler = (e) => {
		const { name, value } = e.target;
		if (e.target.checked) {
			setForm((old) => {
				return { ...old, [name]: value };
			});
		} else {
			setForm((old) => {
				return { ...old, [name]: 0 };
			});
		} 
	};
	
	const SearchSuburb = async (str) => {
		try {
			setsuburb(str);
			if(str != ''){
				let res = await getSearchSuburbAction({str:str});
				setSuburbsearchResults(res.data);
				
				setForm((old) => {
					return { ...old, 'member_suburb': str };
				});
			}
			else{
				setSuburbsearchResults({});
			}
		}
		catch (error) {
			console.error(error);
		}
	};
	
	const handleSelectSuburb = (row) => {
		setsuburb(row);
		
		setForm((old) => {
			return { ...old, 'member_suburb': row };
		});
		
		setSuburbsearchResults({});
	};
	
	function validate() {
		let passwordError = "";
		
		if (form.member_password === '') {
			passwordError = "Password is required."
		}
		
		if (form.member_password != "" && form.member_password != form.member_cpassword) {
			passwordError = "Your password and confirmation password do not match."
		}
		
		if (passwordError) {
			setvalidatioError({
				passwordError
			})
			
			return false
		} else {
			return true
		}
	}
	
	const SubmitForm = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {
	
		}
		else {
			setspinloader(1);   
			let res = await createMemberAction(form);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}login`;
				}, 2000);
			}			 
			else {
				toast.error(res.msg);
				setspinloader(0);
			}
		}
	}
	
	const changeTab = async (str) => {
		let is_agree_termsError = "";
		
		let member_fnameError = "";
		let member_surnameError = "";
		let member_genderError = "";
		let member_emailError = "";
		let member_countryError = "";
		let member_phoneError = "";
		
		var step1 = document.getElementById("register-step1");
		var step2 = document.getElementById("register-details");
		var step3 = document.getElementById("register-secure");
		
		console.log(form)
		
		if(str == 'register-details'){
			
			if (form.is_agree_terms == '' || form.is_agree_terms == 0) {
				is_agree_termsError = "Please agree with terms and conditions."
			}
			
			if(form.is_agree_terms == 1){
				step1.classList.add('d-none')
				step2.classList.add('d-none')
				step3.classList.add('d-none')
				document.getElementById(str).classList.remove('d-none')
				setStep1Error({
						is_agree_termsError
					})
			}
			else{
				if (is_agree_termsError) {
					setStep1Error({
						is_agree_termsError
					})
				}
			}
		}
		else if(str == 'register-secure'){
			if (form.member_fname == '') {
				member_fnameError = "First name is required."
			}
			
			if (form.member_surname == '') {
				member_surnameError = "Surname is required."
			}
			
			if (form.member_gender == '') {
				member_genderError = "Gender is required."
			}
			
			if (form.member_email == '') {
				member_emailError = "Email is required."
			}
			
			if (form.member_country == '') {
				member_countryError = "Please select country."
			}
			
			if (form.member_phone == '') {
				member_phoneError = "Phone number is required."
			}
			
			if(form.member_fname != '' && form.member_surname != '' && form.member_gender != '' && form.member_email != '' && form.member_country != '' && form.member_phone != ''){
				step1.classList.add('d-none')
				step2.classList.add('d-none')
				step3.classList.add('d-none')
				document.getElementById(str).classList.remove('d-none')
			}
			else{
				if(member_fnameError || member_surnameError || member_genderError || member_emailError || member_countryError || member_phoneError){
					setStep2Error({
						member_fnameError, member_surnameError, member_genderError, member_emailError, member_countryError, member_phoneError
					})
				}
			}
		}
		else{
			step1.classList.add('d-none')
			step2.classList.add('d-none')
			step3.classList.add('d-none')
			document.getElementById(str).classList.remove('d-none')
		}
	}
	
	return(
		<>
			<Header />
			
			<div className="add-section mb-3">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Leftnav />
						</div>
						<div className="col-lg-6">
							<Toaster />
							
							<div className="classification-section">
								<h2 className="page-heading"> Register New Member </h2>
								
								<form action='' method='post'>
									<div className="bondiclassified-fromsection">
										<div className="catagoryfrom" id='register-step1'>
											<p className='text-right'><b>Step 1 - Let's get started</b></p>
											<div className="row" >
												<div className="col-md-8 col-sm-12">
													<div className="form-group">
														<label for="memberType">Member Type</label>
														<select className='form-control' name='memberType' id='memberType' onChange={handleSelect} >
															<option value="1">Individual</option>
															<option value="0">Company</option>
														</select>
														
													</div>
												</div>

												<div className="col-md-12 col-sm-12">
													<h4 className='mt-3'> {tncData.title} </h4>
													<div className="termscondition">
														<div className="font-weight-bold mb-3" dangerouslySetInnerHTML={{ __html: tncData.content }}></div>
													</div>
												</div>
												
												<div className="col-md-12 col-sm-12">
													<div className="form-group mt-3">
														<input type='checkbox' name='is_agree_terms' id='is_agree_terms' onChange={checkboxHandler} value='1' />
														<label for="is_agree_terms"> &nbsp; Please agree to our policy</label>
														<br /><span className="text-danger">{step1error.is_agree_termsError}</span>
													</div>
												</div>
												
												<div className="col-md-12 mt-3 pdb-20">
													<button type="button" onClick={() => changeTab('register-details') } className="btn btn-primary login-btn">Next</button>
												</div>
											</div>
										</div>
										<div className="catagoryfrom d-none" id='register-details'>
											<p className='text-right'><b>Step 2 - Some details about you</b></p>
											<div className="row" >
												<div className="col-md-8 col-sm-12">
													<div className="form-group">
														<label for="memberType">First Name <span className='text-danger'>*</span></label>
														<input type='text' className='form-control' name='member_fname' id='member_fname' onChange={inputHandler} />
														<span className="text-danger">{step2error.member_fnameError}</span>
													</div>
												</div>
												
												<div className="col-md-8 col-sm-12">
													<div className="form-group">
														<label for="member_surname">Surname <span className='text-danger'>*</span></label>
														<input type='text' className='form-control' name='member_surname' id='member_surname' onChange={inputHandler} />
														<span className="text-danger">{step2error.member_surnameError}</span>
													</div>
												</div>
												
												<div className="col-md-8 col-sm-12">
													<div className="form-group">
														<label for="member_gender">Gender <span className='text-danger'>*</span></label>
														<select className='form-control' name='member_gender' id='member_gender' onChange={handleSelect}>
															<option value=""> -- Please Select -- </option>
															<option value="0">Male</option>
															<option value="1">Female</option>
														</select>
														<span className="text-danger">{step2error.member_genderError}</span>
													</div>
												</div>
												
												<div className="col-md-8 col-sm-12">
													<div className="form-group">
														<label for="member_email">Email <span className='text-danger'>*</span></label>
														<input type='text' className='form-control' name='member_email' id='member_email' onChange={inputHandler} />
														<span className="text-danger">{step2error.member_emailError}</span>
													</div>
												</div>
												
												<div className="col-md-8 col-sm-12">
													<div className="form-group">
														<label for="member_gender">Where do you live? <span className='text-danger'>*</span></label>
														<select className='form-control' name='member_country' id='member_country' onChange={handleSelect}>
															<option value=""> Please Select a Country </option>
															{countriesList.length > 0 ?
																countriesList.map((row) => (
																	<option value={row.country_code}>{row.country_name}</option>
																))
															: ''}
														</select>
														<span className="text-danger">{step2error.member_countryError}</span>
													</div>
												</div>
												
												{form?.member_country == 'AU' ?
													<div className="col-md-8 col-sm-12">
														<div className="form-group">
															<label for="member_suburb">Suburb</label>
															<div className="suburbtip mb-3"><b>Follow Instruction:</b><br />
																1. Please type first few letters of your suburb.<br />
																2. Wait for suggestions.<br />
																3. Select your suburb.
															</div>
															<input type="text" className="form-control" onChange={(e) => SearchSuburb(e.target.value)} id="member_suburb" placeholder="Suburb.." value={suburb} />
															{SuburbsearchResults.length > 0 ? (
																<ul className="suburbList">
																	{SuburbsearchResults.map((row, index) => (
																		<li key={index} onClick={() => handleSelectSuburb(row.rowlocation)}>
																			{row.rowlocation} 
																		</li>
																	))}
																</ul>
															) : null}
														</div>
													</div>
												: '' }
												
												<div className="col-md-8 col-sm-12">
													<div className="form-group">
														<label for="member_phone">Phone Number <span className='text-danger'>*</span></label>
														<InputMask
                                                            type='text'
                                                            className='form-control'
                                                            name='member_phone'
                                                            id='phone'
                                                            value={form.member_phone}
                                                            onChange={inputPhoneHandler}
                                                            onKeyDown={handleKeyDown}
                                                            mask="____ ___ ___" 
                                                            replacement={{ _: /\d/ }}
                                                            placeholder="____ ___ ___"
                                                            
                                                        />
														<span className="text-danger">{step2error.member_phoneError}</span>
													</div>
												</div>
												
												<div className="col-md-8 col-sm-12">
													<div className="form-group">
														<label for="member_phone">Have a website or blog?</label>
														<input type='text' className='form-control' name='member_webaddress' id='member_webaddress' onChange={inputHandler} />
													</div>
												</div>
												
												<div className="col-md-8 col-sm-12">
													<div className="form-group">
														<label for="member_mtype">Membership Type</label>
														<select className='form-control' name='member_mtype' id='member_mtype' onChange={handleSelect}>
															<option value="1">Basic</option>
															<option value="2">VIP</option> 
														</select>
													</div>
													
													{form.member_mtype == "2" ?
														<>
															<div>Unlimited Ads</div>
															<div>VIP Membership Price $1499</div>
														</>
													: '' }
												</div>
												
												
												<div className="col-md-12 mt-3 pdb-20">
													<button type="button" onClick={() => changeTab('register-step1') } className="btn btn-primary login-btn">Back</button>
													<button type="button" onClick={() => changeTab('register-secure') } className="btn btn-primary login-btn ml-3">Next</button>
												</div>
											</div>
										</div>
										<div className="catagoryfrom d-none" id='register-secure'>
											<p className='text-right'><b>Last Step - Secure yourself!</b></p>
											<div className="row" >
												<div className="col-md-8 col-sm-12">
													<div className="form-group">
														<label for="member_password">Password <span className='text-danger'>*</span></label>
														<input type='password' className='form-control' name='member_password' id='member_password' onChange={inputHandler} />
													</div>
												</div>
												
												<div className="col-md-8 col-sm-12">
													<div className="form-group">
														<label for="member_cpassword">Confirm Password <span className='text-danger'>*</span></label>
														<input type='password' className='form-control' name='member_cpassword' id='member_cpassword' onChange={inputHandler} />
													</div>
													<span className='text-danger'>{validatioError.passwordError}</span>
												</div>
												
												<div className="col-md-12 mt-3 pdb-20">
													<button type="button" onClick={() => changeTab('register-details') } className="btn btn-primary login-btn mr-3">Back</button>
													{spinloader == '0' ?
													<button type="button" onClick={SubmitForm} className="btn btn-primary login-btn">Register</button>
													:
													<button disabled className='btn btn-primary login-btn' >Registring.. <i className="fa fa-spinner fa-spin validat"></i></button>
													}
													
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div className="col-lg-3">
							<Rightnav />
						</div>
					</div>
				</div>
			</div>
			
			<Footer />
		</>
	)
	
}

export default Register;
