import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import config from "../coreFIles/config";
import toast, { Toaster } from 'react-hot-toast';
import Header from '../directives/header'
import Leftnav from '../directives/leftSidebar'
import Rightnav from '../directives/rightSidebar'
import Footer from '../directives/footer'
import Cookies from 'js-cookie'
import ReCAPTCHA from 'react-google-recaptcha';
import { getadDataAction, getadImgesAction, getRelatedAdsAction, getMemberFavouriteAdsAction, SaveFavouriteAdsAction, reportthisadAction, emailtofriendAction } from '../Action/action';
import moment from 'moment';

const loginData = (!Cookies.get('loginSuccessMember')) ? [] : JSON.parse(Cookies.get('loginSuccessMember'));


const Domainstyle = (!Cookies.get('domainstyle')) ? [] : JSON.parse(Cookies.get('domainstyle'));
// style
const timeColor = `#${Domainstyle[0]?.time_color}`;
const headerButtonColor = `#${Domainstyle[0]?.header_buttoncolor}`;
const headerMenuColor = `#${Domainstyle[0]?.header_menu}`;
const headerMenuHoverColor = `#${Domainstyle[0]?.header_menu_hover}`;
const header_search_text = `#${Domainstyle[0]?.header_search_text}`
// style end
const Addetails = () => {
	const navigate = useNavigate();

	const { id } = useParams();
	const { any } = useParams();
	
	const [adData, setadData] = useState({});
	const [spinloader, setspinloader] = useState(0);
	const [adImges, setadImges] = useState({});
	const [relatedAds, setRelatedAds] = useState({});
	const [adUrl, setAdUrl] = useState('');
	const [catUrl, setCatUrl] = useState('');
	const [getfavAds, setgetfavAds] = useState([])
	// for report page
	const [showreport, setshowreport] = useState(0)
	const [validationError, setvalidationError] = useState({});
	const recaptchaRef = useRef(null);
	
	// email A friend 

	const [form, setForm] = useState({
		name: loginData.fname,
		title: any,
		email: loginData.email,
		msg: '',
		

	});

	useEffect(() => {
		getadData();
		getadImges();
	

		if (!loginData) {
			return 
		}
		else {
			GetFavoriteAds()
		}
	}, [])


	// for getting your favourites ads
	const GetFavoriteAds = async () => {
		try {
			if (!Cookies.get('loginSuccessMember')) {
				return null
			} else {

				const res = await getMemberFavouriteAdsAction({ memberid: loginData?.id });

				if (res.success) {

					const adId = res.data;
					setgetfavAds(adId)

				} else {

					toast.error(`Failed to fetch favorite ads: ${res.error}`);
				}
			}
		} catch (error) {

			toast.error(`An error occurred while fetching favorite ads: ${error}`);
		}
	};
	// favouriate ads
	const handleFavorite = async (adId) => {
		if (!Cookies.get('loginSuccessMember')) {
			toast.error('Please login first.');
			return null;
		}
		try {
			let res = await SaveFavouriteAdsAction({
				memberid: loginData.id,
				adid: adId
			});
			if (res.success) {
				toast.success(res.msg);
				GetFavoriteAds()
			}
			else {
				toast.error(res.msg);
				GetFavoriteAds()
			}


		} catch (error) {
			console.error('Error saving favorite ad:', error);
			toast.error('Failed to save ad to favorites');
		}


	};
	const getadData = async () => {
		setspinloader(1)
		let res = await getadDataAction({ id: id, any: any });

		if (res.success) {
			setadData(res.data);
			setspinloader(0)
			getRelatedAds(res.data.category_id,id);
		}
		else {
			window.location.href = `${config.baseUrl}`
		}

	};
	const getadImges = async () => {
		let res = await getadImgesAction({ id: id });
		if (res.success) {
			setadImges(res.data);
		} else {
			setadImges('');
		}
	};
	// related ads
		const getRelatedAds = async (id,category_id) => {

		var currentDate = new Date();
		currentDate = moment(currentDate).subtract(2, "year");
		currentDate = moment(currentDate).format('YYYY-MM-DD');

		let res = await getRelatedAdsAction({id,category_id, date: currentDate });
	
		if (res.success) {
			setRelatedAds(res.data);
		} else {
			setRelatedAds('');
		}


	};
	// function to redirect to ad url on click ad title
	const handleAdUrl = (row) => {
		var pagenewUrl = '';
		pagenewUrl = config.baseUrl + 'ad/' + row.id + '/' + row.parent_cat.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + row.cat_name.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + row.title.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase();

		window.location.href = `${pagenewUrl}`;
		
	};
	// function to redirect to category ads page on click category title
	const handleCatUrl = (row) => {
		var pagenewUrl = '';
		pagenewUrl = config.baseUrl + 'cat/' + row.category_id + '/' + row.parent_cat.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + row.cat_name.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase();

		window.location.href = `${pagenewUrl}`;
	};
	// function to create ad url on hover ad title
	const getAdUrl = (row) => {
		var pagenewUrl = '';
		pagenewUrl = config.baseUrl + 'ad/' + row.id + '/' + row.parent_cat.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + row.cat_name.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + row.title.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase();
		setAdUrl(pagenewUrl);

	};

	// function to create category url on hover ad title
	const getCatUrl = (row) => {
		var pagenewUrl = '';
		pagenewUrl = config.baseUrl + 'cat/' + row.category_id + '/' + row.parent_cat.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + row.cat_name.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase();

		setCatUrl(pagenewUrl);
	};
	const handleadUrl = (row) => {
		const pagenewUrl = `${config.baseUrl}cat/${row.category_id}/${row.parent_cat.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase()}-${row.cat_name.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase()}?title=${encodeURIComponent(row.title)}&id=${row.id}`;

		window.location.href = pagenewUrl;
	};
	const SubmitForm = async (e) => {
		e.preventDefault();
		const isValid = validate();

		if (!isValid) return;

		setspinloader(1);

		let res = await reportthisadAction({
			...form, adid: id,
			title: adData?.title,
			url:`http://localhost:3000${adUrl}`

		});

		if (res.success) {
			toast.success(res.msg);

		} else {
			toast.error(res.msg);
		}
		setspinloader(0);
		setTimeout(() => {
			window.location.href = `${config.baseUrl}`
		}, 3000);
	};

	const inputHandler = (e) => {
		const { name, value } = e.target;
		setForm((prev) => ({
			...prev,
			[name]: value,


		}));
	};
	const onCaptchaChange = (value) => {
		setForm((prev) => ({ ...prev, captchaValue: value }));
	};
	function validate() {
		let nameError = "";
		let emailError = "";
		let msgError = "";
		let captchaValueError = "";
		if (form.name === '') nameError = "Name is required.";
		if (form.email === '') emailError = "Email is required.";
		if (form.msg === '') msgError = "Message is required.";
		if (form.captchaValue === '') captchaValueError = "Captcha is required.";
		if (nameError || emailError || msgError || captchaValueError) {
			setvalidationError({
				nameError, emailError, msgError, captchaValueError
			});
			return false;
		} else {
			setvalidationError({
				nameError, emailError, msgError, captchaValueError
			});
			return true;
		}

	}
	const ShowReportFrom = (adData) => {
		if (!loginData) {
			window.location.href = `${config.baseUrl}`
		} else {
			setshowreport(1)
			var pagenewUrl = '';
			pagenewUrl = config.baseUrl + 'ad/' + adData.id + '/' + adData.parent_cat.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + adData.cat_name.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + adData.title.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase();

			setAdUrl(pagenewUrl);
			window.scrollTo(1, 1)
		}
	}
	// email friend
	const SubmitFormfriend = async (e) => {
		e.preventDefault();
		const isValid = validate();

		if (!isValid) return;

		setspinloader(1);

		let res = await emailtofriendAction({
			...form, adid: id,
			title: adData?.title,
			url:`http://localhost:3000${adUrl}`
		});

		if (res.success) {
			toast.success(res.msg);

		} else {
			toast.error(res.msg);
		}
		setspinloader(0);
		setTimeout(() => {
			window.location.href = `${config.baseUrl}`
		}, 3000);
		console.log(form)

	};
	const ShowFriendFrom = (adData) => {
		if (!loginData) {
			window.location.href = `${config.baseUrl}`
		}
		else {
			setshowreport(2)
			var pagenewUrl = '';
			pagenewUrl = config.baseUrl + 'ad/' + adData.id + '/' + adData.parent_cat.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + adData.cat_name.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + adData.title.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase();

			setAdUrl(pagenewUrl);
			window.scrollTo(1, 1)
		}
	}
	
	const handlePrev = () => {
	};
	
    // const handleNext = () => {
    //     if (relatedAds < totalPages - 1) {
    //         setCurrentPage((prev) => prev + 1);
    //     }
    // };
	return (
		<>
			<Header />
			<Toaster />
			<div className="product-single">
				<div className="container">
					{spinloader == 0 ?
						<div className="baner-small">
							<Link className="home-icon" to={config.baseUrl}><i className="fa-solid fa-house-chimney"></i></Link>
							<span>/</span>
							<Link to="#">{adData.parent_cat}</Link>
							<span>/</span>
							<Link to={catUrl} onClick={() => handleCatUrl(adData)} onMouseOver={() => getCatUrl(adData)} onMouseOut={() => setCatUrl('')}>{adData.cat_name} </Link>
							<span>/</span>
							<Link to="#">{adData.title}</Link>
						</div>
						: ''}
				</div>
			</div>

			<div className="add-section">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Leftnav />
						</div>
						<div className="col-lg-6">
							<div className="plan-section">
								{spinloader === 0 ? (
									<>
										<div className="arrow-btn">
											<div className="back-btn" >
												<a to="#" onClick={() => navigate(-1)} style={{background:headerButtonColor}}><span><i className="fa-solid fa-angles-left" ></i></span>Back</a>
											</div>
											<div className="viewbtn">
												<div className="prev-btn">
													<a to="#" onClick={()=>navigate(-1)} style={{background:headerButtonColor}}><span><i className="fa-solid fa-angles-left" ></i></span>Prev</a>
												</div>
												<div className="next-btn">
													<a to="#" onClick={handlePrev} style={{background:headerButtonColor}}>Next<span><i className="fa-solid fa-angles-right"></i></span></a>
												</div>
											</div>
										</div>
										{showreport === 0 ? (
											<>
												<div className="heading-super">
													<h6 style={{background:headerMenuColor}}>{adData.title}</h6>
												</div>
												<div>
													{/* <div className="item-purchased mb-3 mt-3 ad-description" dangerouslySetInnerHTML={{ __html: adData?.discription }}></div> */}
										{adImges.length > 0 ? (
											<><div className="blogs" id="lightgallery">
												{adImges.map((row, index) => (
													<div key={index} className="gallery-section">
														<Link to="#">
															<img alt={adData.title} src={config.apiUrl + row.addImg} />
														</Link>
													</div>
												))}
											</div>
											</>
										) : (
											''
										)}


										{parseInt(adData.parent_cat_id) === parseInt(config.cat_per) && (
											<>
												<div className="location">
													<p><span><i className="fa-solid fa-user"></i></span> Age: {adData.age}</p>
												</div>
												<div className="location">
													<p><span><i className="fa-solid fa-venus-double"></i></span> Gender: {adData.sex === 0 ? 'Male' : 'Female'}</p>
												</div>
											</>
										)}</div>
												<div className="location">
													<p>
														<Link to="#">
															<span><i className="fa-solid fa-location-dot"></i></span>
															{adData.suburb} ({adData.area})
														</Link>
													</p>
												</div>
												
												{parseInt(adData.parent_cat_id) === parseInt(config.cat_real) && (
													<>
														<div className="location">
															<p><span><i className="fa-regular fa-building"></i></span> Property Type: {adData.property_type}</p>
														</div>
														<div className="location">
															<p><span><i className="fa-solid fa-street-view"></i></span> Street No: {adData.street_number}</p>
														</div>
														<div className="location">
															<p><span><i className="fa-solid fa-street-view"></i></span> Street Name: {adData.street_name}</p>
														</div>
														<div className="location">
															<p><span><i className="fa-solid fa-dollar-sign"></i></span> ${adData.sell_price}</p>
														</div>
														<div className="location">
															<p><span><i className="fa-solid fa-bed"></i></span> Bedrooms: {adData.bed_rooms}</p>
														</div>
														<div className="location">
															<p><span><i className="fa-regular fa-building"></i></span> Unit / Flat: {adData.unit_flat}</p>
														</div>
													</>
												)}

												{parseInt(adData.parent_cat_id) === parseInt(config.cat_buy) && (
													<div className="location">
														<p><span><i className="fa-solid fa-dollar-sign"></i></span> ${adData.sell_price}</p>
													</div>
												)}

												{parseInt(adData.parent_cat_id) === parseInt(config.cat_whats) && (
													<>
														<div className="location">
															<p><span><i className="fa-solid fa-calendar-days"></i></span> Date: {adData.formatted_whatson_date}</p>
														</div>
														<div className="location">
															<p><span><i className="fa-regular fa-clock"></i></span> Time: {adData.time_whatson}</p>
														</div>
														<div className="location">
															<p><span><i className="fa-solid fa-dollar-sign"></i></span> ${adData.sell_price}</p>
														</div>
													</>
												)}

												{parseInt(adData.parent_cat_id) === parseInt(config.cat_jobs) && (
													<div className="location">
														<p><span><i className="fa-solid fa-briefcase"></i></span> Work type: {adData.job_work_type}</p>
													</div>
												)}

												{parseInt(adData.parent_cat_id) === parseInt(config.cat_business) && (
													<>
														<div className="location">
															<p><span><i className="fa-solid fa-user-tag"></i></span> ABN: {adData.abn}</p>
														</div>
														<div className="location">
															<p><span><i className="fa-regular fa-circle-check"></i></span> Accreditation: {adData.accreditation}</p>
														</div>
													</>
												)}

											</>
										) : ''}

										{showreport === 0 ?

											<div className="icon-socialmedia user-icon" >
												<div className="heart" >
													{getfavAds.includes(adData.id) ?
														(<a
															className="fevcls"
															id='heart'
															title=""
															onClick={() => handleFavorite(adData.id)}
														>
															<i className="fa fa-heart" aria-hidden="true"></i>
														</a>)
														:
														(<a
															className="fevcls"
															title=''

															id='heart'
															onClick={() => handleFavorite(adData.id)}
														>
															<i
																className="fa-regular fa-heart" aria-hidden="true"	></i>
														</a>
														)
													}
												</div>

												<div className="facebook">
													<Link to="#"><i className="fa-brands fa-facebook-f"></i></Link>
												</div>
												<div className="facebook twiter">
													<Link to="#"><i className="fa-solid fa-x"></i></Link>
												</div>
												<div className="facebook linkdin">
													<Link to="#"><i className="fa-brands fa-linkedin-in"></i></Link>
												</div>
												<div className="facebook piterest">
													<Link to="#"><i className="fa-brands fa-pinterest-p"></i></Link>
												</div>
												<div className="facebook email">
													<Link to="#"><i className="fa-solid fa-envelope"></i></Link>
												</div>
												<div className="facebook text-icon">
													<Link to="#"><i className="fa-solid fa-t"></i></Link>
												</div>


												<div className="email-btn">
													<a href="javascript:;" onClick={() => ShowFriendFrom(adData)} style={{background:headerButtonColor}}>Email A Friend</a>
												</div>
												<div className="email-btn">
													<a href="javascript:;" onClick={() => ShowReportFrom(adData)} style={{background:headerButtonColor}}>Report</a>
												</div>

											</div>
											: ""}

										
								
										{showreport === 1 ? 
										(<div className="classification-section" style={{ marginTop: "20px" }}>
											<h2 className="page-heading" style={{background:headerMenuColor}}>Report This Post</h2>
											<h2 className="page-heading"style={{background:headerMenuColor}}>{adData.title}</h2>
											<form action='' method='post'>
												<div className="bondiclassified-fromsection">
													<div className="catagoryfrom">
														<div className="row">
															<div className="col-md-12 col-sm-12">
																<div className="form-group">
																	<label htmlFor="name">Name <span className='text-danger'>*</span></label>
																	<input type='text' className='form-control' name='name' id='name' onChange={inputHandler} value={form.name} />
																	<span className="text-danger">{validationError.nameError}</span>
																</div>
															</div>
															<div className="col-md-12 col-sm-12">
																<div className="form-group">
																	<label htmlFor="email">Email <span className='text-danger'>*</span></label>
																	<input type='text' className='form-control' name='email' id='email' onChange={inputHandler} value={form.email} />
																	<span className="text-danger">{validationError.emailError}</span>
																</div>
															</div>
															<div className="col-md-12 col-sm-12">
																<div className="form-group">
																	<label htmlFor="msg">Message <span className='text-danger'>*</span></label>
																	<textarea className="form-control" name="msg" rows='5' id='msg' onChange={inputHandler} value={form.msg}></textarea>
																	<span className="text-danger">{validationError.msgError}</span>
																</div>
															</div>
															<div className="col-md-12 col-sm-12">
																<div className="form-group">
																	<ReCAPTCHA
																		ref={recaptchaRef}
																		sitekey={config.site_key}
																		onChange={onCaptchaChange}
																	/>
																	<span className="text-danger">{validationError.captchaValueError}</span>
																</div>
															</div>
															<div className="col-md-12 mt-3 pdb-20">
																{spinloader === 0 ? (
																	<button type="button" onClick={SubmitForm} className="btn btn-primary login-btn" style={{background:headerButtonColor}}>
																		Send
																	</button>
																) : (
																	<button disabled className="btn btn-primary login-btn" style={{background:headerButtonColor}}>
																		Sending.. <i className="fa fa-spinner fa-spin validat"></i>
																	</button>
																)}
															</div>
														</div>
													</div>
												</div>
											</form>
										</div>
										) : ''}

										{showreport === 2 ? (
											<>
												<div className="classification-section">
													<h2 className="page-heading" style={{background: headerMenuColor}}> Email To Friend </h2>
													<form action='' method='post'>
														<div className="bondiclassified-fromsection">
															<div className="catagoryfrom">

																<div className="row">


																	<div className="col-md-12 col-sm-12">
																		<div className="form-group">
																			<label htmlFor="email">Email <span className='text-danger'>*</span></label>
																			<input type='text' className='form-control' name='email' id='email' onChange={inputHandler} value={form.email} />
																			<span className="text-danger">{validationError.emailError}</span>
																		</div>
																	</div>

																	<div className="col-md-12 col-sm-12">
																		<div className="form-group">
																			<label htmlFor="email">Friend Email <span className='text-danger'>*</span></label>
																			<input type='text' className='form-control' name='femail' id='fname' onChange={inputHandler} value={form.femail} />
																			<span className="text-danger">{validationError.emailError}</span>
																		</div>
																	</div>


																	<div className="col-md-12 col-sm-12">
																		<div className="form-group">
																			<label htmlFor="msg">Message <span className='text-danger'>*</span></label>
																			<textarea className="form-control" name="msg" rows='5' id='msg' onChange={inputHandler} value={form.msg}></textarea>
																			<span className="text-danger">{validationError.msgError}</span>
																		</div>
																	</div>
																	<div className="col-md-12 col-sm-12">
																		<div className="form-group">
																			<ReCAPTCHA
																				ref={recaptchaRef}
																				sitekey={config.site_key}
																				onChange={onCaptchaChange}
																			/>
																			<span className="text-danger">{validationError.captchaValueError}</span>
																		</div>
																	</div>
																	<div className="col-md-12 mt-3 pdb-20">
																		{spinloader === 0 ? (
																			<button
																				type="button"
																				onClick={SubmitFormfriend}
																				className="btn btn-primary login-btn"
																				style={{background:headerMenuColor}}
																			>
																				Send
																			</button>
																		) : (
																			<button disabled className="btn btn-primary login-btn" style={{background:headerMenuColor}}>
																				Sending.. <i className="fa fa-spinner fa-spin validat"></i>
																			</button>
																		)}
																	</div>
																</div>
															</div>
														</div>
													</form>
												</div>

											</>) : ""}


									</>
								) : (
									<div className="loaderDiv">
										<img className="loaderImg" src={config.baseUrl + "img/loader.gif"} height={50} width={50} />
									</div>
								)}
								{relatedAds.length > 0 && (
											<>
												<div style={{ marginTop: "20px" }} className="heading-super">
													<h6>OTHER RELATED ADS</h6>
												</div>
												{relatedAds.map((row, index) => (
													<div key={index} className={index % 2 === 0 ? 'section-listing' : 'section-listing section-listing-new'}>
														<div className="main-item">
															<div className="fast-block">
																{row.addImg && row.addImg !== '' ? (
																	<img src={config.apiUrl + row.addImg} alt={row.title} />
																) : (
																	<img src={config.baseUrl + "img/noImage.png"} alt={row.title} />
																)}
															</div>
															<div className="second-block">
																<Link className="fordhead" to={adUrl} onClick={() => handleAdUrl(row)} onMouseOver={() => getAdUrl(row)} onMouseOut={() => setAdUrl('')}>
																	{row.title}
																</Link>
																<div className="price">
																	<p><strong>${row.sell_price}</strong></p>
																</div>
																<p><span><i className="fa-solid fa-location-dot"></i></span> {row.suburb} ({row.area})</p>
															</div>
															{getfavAds.includes(row.id) ?
																(<a
																	className="fevcls"
																	title=""
																	onClick={() => handleFavorite(row.id)}
																>
																	<i className="fa fa-heart" aria-hidden="true"></i>
																</a>)
																:
																(<a
																	className="fevcls"
																	title=''
																	onClick={() => handleFavorite(row.id)}
																>
																	<i
																		className="fa-regular fa-heart" aria-hidden="true"	></i>
																</a>
																)
															}
														</div>
													</div>
												))}
											</>
										)}

							</div>

						</div>
						<div className="col-lg-3">
							<Rightnav />
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default Addetails;
